import React from 'react';

import Footer from '../../components/common/Footer';

import buildingIcon from '../../assets/images/icons/building-icon.svg';
import piechartIcon from '../../assets/images/icons/piechart-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';
import syndicateSvg from '../../assets/images/icons/syndicate-icon.svg';
import usersIcon from '../../assets/images/icons/users-icon.svg';

import portalExample from '../../assets/images/examples/paperos-portal.png';

import { Button, Sitemap, TestimonialsList, Topbar } from '../../components/common';
import { Testimonials } from '../../utils/Testimonials';

import './Capital.scss';
import { FloatingList } from '../../components/common/FloatingList/FloatingList';
import { generateBem } from '../../utils/generateBem';

const bem = generateBem('Capital');

const floatingList = [
  {
    icon: buildingIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Formation & Administration',
  },
  {
    icon: piechartIcon,
    title: 'Investor/LP Portals',
  },
  {
    icon: stackIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Reporting Tools',
  },
  {
    icon: scalesIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Doc & Filing Automation',
  },
  {
    icon: usersIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Deal Management',
  },
  {
    icon: syndicateSvg,
    title: 'AI Enabled Diligence',
  },
];

const Capital = ({ location: { search } }) => (
  <>
    <Topbar />
    <section className={`${bem('heroSection')} container`}>
      <div className="row">
        <div className={bem('heroColumn')}>
          <div className={bem('heroTextWrapper')}>
            <h1 className={bem('heroHeading')}>
              Private Capital
              <br />
              at Scale
            </h1>
            <h3 className={bem('heroSubtext')}>
              Your home for formation, fundraising, compliance, & investment operations.
            </h3>
          </div>
          <Button
            className={bem('heroButton')}
            isFlat
            href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
          >
            Get Started
          </Button>
        </div>
        <div className={bem('heroImageWrapper')}>
          <img
            className={bem('heroImage')}
            src={portalExample}
            alt="PaperOS Site Example"
          />
          <div className={bem('floatingList')}>
            <FloatingList items={floatingList} zIndex="80" />
          </div>
        </div>
      </div>
    </section>
    <section className="container container--light">
      <div className="row">
        <TestimonialsList listItems={Testimonials} />
      </div>
    </section>
    {/* <section className="container container--light">
      <div className="row">
        <TestimonialsList listItems={Testimonials} />
      </div>
    </section> */}
    <Sitemap />
    <Footer />
  </>
);

export default Capital;
