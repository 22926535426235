import React from 'react';

import Footer from '../../components/common/Footer';

import buildingIcon from '../../assets/images/icons/building-icon.svg';
import raiseIcon from '../../assets/images/icons/raise-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';
import syndicateIcon from '../../assets/images/icons/syndicate-icon.svg';
import usersIcon from '../../assets/images/icons/users-icon.svg';

import reportsExample from '../../assets/images/examples/paperos-reports.png';

import { Button, Sitemap, Topbar } from '../../components/common';

import './Practice.scss';
import { FloatingList } from '../../components/common/FloatingList/FloatingList';
import { generateBem } from '../../utils/generateBem';

const bem = generateBem('Practice');

const floatingList = [
  {
    icon: usersIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Client Platform',
  },
  {
    icon: syndicateIcon,
    title: 'AI Toolkit',
  },
  {
    icon: buildingIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Entity Management',
  },
  {
    icon: raiseIcon,
    title: 'Transaction Lifecycle',
  },
  {
    icon: scalesIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Filing & Doc Automation',
  },
  {
    icon: stackIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Knowledge Licensing',
  },
];

const Practice = ({ location: { search } }) => (
  <>
    <Topbar />
    <section className={`${bem('heroSection')} container`}>
      <div className="row">
        <div className={bem('heroColumn')}>
          <div className={bem('heroTextWrapper')}>
            <h1 className={bem('heroHeading')}>Practice at Scale</h1>
            <h3 className={bem('heroSubtext')}>
              Unlock scale in client-facing & back-office operations.
            </h3>
          </div>
          <Button
            className={bem('heroButton')}
            isFlat
            href="https://share.hsforms.com/10KQq5Zo2Tx6UFEsRLy6Deg2rvzm"
          >
            Get Started
          </Button>
        </div>
        <div className={bem('heroImageWrapper')}>
          <img
            className={bem('heroImage')}
            src={reportsExample}
            alt="PaperOS Site Example"
          />
          <div className={bem('floatingList')}>
            <FloatingList items={floatingList} zIndex="80" />
          </div>
        </div>
      </div>
    </section>
    {/* <section className="container container--light">
      <div className="row">
        <TestimonialsList listItems={Testimonials} />
      </div>
    </section> */}
    <Sitemap />
    <Footer />
  </>
);

export default Practice;
